<template>
  <div class="home">
   <button @click='sign' style='margin-bottom:20px;font-size:30px;'>签名</button>
  <div v-for="(x,key) in pair_list" :key='key' style='font-size:12px'>{{x}}</div>
  </div>
</template>

<script>
// @ is an alias to /src
import Web3 from "web3";

export default {
  name: 'Home',
  components: {
  },
  data(){
    return {
      web3:null,
      sign_msg:"",
      pair_list:[]
    }
  },
  methods:{
    async sign(){
      let self = this
      console.log(self.web3)
      let accounts = await self.web3.eth.getAccounts();
      let address = accounts[0]
      console.log(accounts)

      let c = await web3.eth.personal.sign(web3.utils.fromUtf8("LogIn"), address);
      console.log(c)
      self.pair_list.push(`"${address}":"${c}",`)
      
    }
  },

  async mounted() {
    let self = this;
    let web3Provider;
    if (window.ethereum) {
      web3Provider = window.ethereum;
      try {
        // 请求用户授权
        await window.ethereum.enable();
      } catch (error) {
        // 用户不授权时
      }
    } else if (window.web3) {
      // 老版 MetaMask Legacy dapp browsers...
      web3Provider = window.web3.currentProvider;
    } else {
      alert("当前无法链接钱包");
    }
    web3 = new Web3(web3Provider);
    self.web3 = web3;
    
  },
}
</script>
